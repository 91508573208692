$( window ).on( 'load', () => {

  $( '#toggle-menu' ).on( 'click', () => {
      $( '.nav' ).toggleClass( 'open' );
  });

  $( '.scroll-top' ).on( 'click', ( e ) => {
    e.preventDefault();
    $( 'html, body, .modal' ).scrollTop( 0 );
  });
  
  // check on load if the nav is checked uncheck it for when a user hits the back button
  if ( $( '#toggle-menu' ).is( ':checked' ) ) {
    $( '#toggle-menu' ).prop( 'checked', false );
  }

  // addding no scroll when the menu is open
  const checkbox = document.getElementById( 'toggle-menu' );

  // Hide and remove
  checkbox.addEventListener( 'change', () => {
    $( 'body' ).toggleClass( 'no-scroll' );
  });
});
   
if ( $( '.menu-item-has-children' ).length ) {

  // DESKTOP HOVER STATE
  // $( '.menu-item-has-children' ).click( function() {
  //     $( this ).parents( '.nav-primary' ).toggleClass( 'sub-menu-hover' );
  // });

  // MOBILE NAV SUB MENU ACCORDION
  $( '.menu-item-has-children' ).on( 'click', function( e ) {
    // e.preventDefault();

    var $this = $( this );
    var $content = $this.find( '.sub-menu' );
    
    $this.parents( '.nav-primary' ).find( '.sub-menu' ).css( 'max-height', '0' );

    if ( $this.hasClass( 'open' ) ) {
      $this.removeClass( 'open' );
    } else {
      $this.parents( '.nav-primary' ).find( '.menu-item-has-children' ).removeClass( 'open' );
      $this.addClass( 'open' );
      $content.css( 'max-height', $content[0].scrollHeight );
    }
  });
} 

var position = $( window ).scrollTop(); 
var headerHeight = $( '.page-header' ).height();
var navHeight = $( '.nav' ).height();
// should start at 0

$( window ).on( 'scroll', () => {
  var scroll = $( window ).scrollTop();

  if( scroll > position || scroll <= navHeight) {
    $( '.nav' ).removeClass( 'nav-fix' );
  } else {
    $( '.nav' ).addClass( 'nav-fix' );
  }
  position = scroll;

  if ( scroll >= headerHeight ) {
    $( '.nav' ).addClass( 'nav-animate' );
  } else {
    $( '.nav' ).removeClass( 'nav-animate' );
  }
});


$( '.video-button' ).on( 'click', ( e ) => {
  e.preventDefault();

	if ( $( '.hero-video' ).get( 0 ).paused ) {
		$( '.hero-video' ).get( 0 ).play();
		$( 'body' ).addClass( 'video-playing' );

	} else { 
		$( '.hero-video' ).get( 0 ).pause();
		$( 'body' ).removeClass( 'video-playing' );
	}
	return false;
});

// forms stuff

if( $( '.select' ).length ){
  $( '.select' ).on( 'change', function( change ){
    $(this).css('color', 'black')
  })
}

if( $( '.dropdown-el' ).length ) {
  $( '.dropdown-el' ).click(function( e ) {
    e.preventDefault();
    e.stopPropagation();
    $( this ).toggleClass( 'expanded' );
    $( '#'+$( e.target ).attr( 'for' ) ).prop( 'checked', true );
  });
  $( document ).click( function() {
    $( '.dropdown-el' ).removeClass( 'expanded' );
  });
}

// // remove nav toggle on doc click
$( document ).on('click', function( e ) {
  if ( ! $( e.target ).closest( '.menu-item-has-children' ).length ) {
    $( '.menu-item-has-children' ).removeClass( 'open' )
    $( '.nav-primary' ).find( '.sub-menu' ).css( 'max-height', '0' );
  }
});