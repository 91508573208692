import Glide from '@glidejs/glide';
import $ from 'jquery';

if( $( '.glide' ).length ) {
  var slider2 = document.querySelectorAll( '.slider-track-2' );
  var slider3 = document.querySelectorAll( '.slider-track-3' );
  var sliderLeft = document.querySelectorAll( '.slider-track-left' );
  var sliderRight = document.querySelectorAll( '.slider-track-right' );
  var sliderFloor = document.querySelectorAll( '.slider-track-floorplans' );
  var sliderModal = document.querySelectorAll( '.slider-track-modal' );

  if( $( sliderLeft ).length ){
    for ( var i = 0; i < sliderLeft.length; i++ ) {
      new Glide(sliderLeft[i], {
        type: 'carousel',
        animationDuration: 600,
        perTouch: 1,
        perView:2, 
        touchRatio: 1,
        peek: {
          before: 100, 
          after: 0 }, 
        gap: 64, 
        breakpoints: {
          1800: {
            perView:1,
            peek: {
              before: 400, 
              after: 0 } 
          },
          1600: {
            perView:1,
            peek: {
              before: 350, 
              after: 0 } 
          },
          1300: {
            perView:1,
            peek: {
              before: 300, 
              after: 0 } 
          },
          767: {
            perView:1,
            peek: {
              before: 0, 
              after: 0 } 
          }
        }
      } ).mount()
    }
  }

  if( $( sliderRight ).length ){
    for ( var i = 0; i < sliderRight.length; i++ ) {
      new Glide( sliderRight[i], {
        type: 'carousel',
        animationDuration: 600,
        perTouch: 1,
        perView:2, 
        touchRatio: 1,
        peek: {
          before: 0, 
          after: 100 }, 
        gap: 64, 
        breakpoints: {
          1800: {
            perView:1,
            peek: {
              before: 0, 
              after: 400 } 
          },
          1600: {
            perView:1,
            peek: {
              before: 0, 
              after: 350 } 
          },
          1300: {
            perView:1,
            peek: {
              before: 0, 
              after: 300 } 
          },
          767: {
            perView:1,
            peek: {
              before: 0, 
              after: 0 } 
          }
        }
      } ).mount()
    }
  }

  if( $( slider3 ).length ){
    for ( var i = 0; i < slider3.length; i++ ) {
      new Glide( slider3[i], {
        type: 'carousel',
        animationDuration: 600,
        perTouch: 1,
        perView:2, 
        touchRatio: 1,
        peek:200, 
        gap: 64, 
        breakpoints: {
          960: {
            perView:1, 
            peek: {
              before: 0, 
              after: 100 }, 
            gap: 16
          }
        }
      } ).mount()
    }
  }

  if( $( slider2 ).length ){
    for ( var i = 0; i < slider2.length; i++) {
      new Glide( slider2[i], {
        type: 'carousel',
        animationDuration: 600,
        perTouch: 1,
        perView: 3, 
        touchRatio: 1,
        peek:128, 
        gap: 64,
        breakpoints: {
          1700: {
            perView: 1, 
            peek: 250,
            gap: 64
          },
          960: {
            perView: 1, 
            peek: 100,
            gap: 32
          },
          480: {
            perView: 1, 
            peek: {
              before: 16, 
              after: 50 }, 
            gap: 16
          }
        }
      } ).mount()
    }
  }

  if( $( sliderModal ).length ){
    for ( var i = 0; i < sliderModal.length; i++) {
      new Glide( sliderModal[i], {
        type: 'carousel',
        animationDuration: 600,
        perTouch: 1,
        perView: 1, 
        touchRatio: 1,
        peek:148, 
        gap: 24,
        breakpoints: {
          960: {
            peek: 100,
            gap: 16
          },
          480: {
            peek: 50, 
            gap: 8
          }
        }
      } ).mount()
    }
  }


  if( $( sliderFloor ).length ){

    var sliderButton = document.querySelectorAll( '.slider-button' );

    for ( var i = 0; i < sliderFloor.length; i++ ) {
    
      let floorTrack = new Glide( sliderFloor[i], {
        type: 'slider',
        animationDuration: 600,
        perTouch: 1,
        perView:1, 
        touchRatio: 1
      } ).mount()
    
    
      Array.from( sliderButton ).forEach( el => {
        el.addEventListener( 'click', ( e ) => {
          floorTrack.go( '='+(e.target.dataset.index ) ) 
        })
      })
    }
  }

  var sliderNav = document.querySelectorAll( '.slider-nav' );
  
  if( $( sliderNav ).length ){
    var sliderTrack = document.querySelectorAll( '.slider-track' );

    for ( var i = 0; i < sliderNav.length; i++ ) {
    
      let thisTrack = new Glide( sliderTrack[i], {
        animationDuration: 600,
        perTouch: 1,
        perView:1,
        gap: 0, 
        touchRatio: 1
      } );
      thisTrack.mount()
    
      let thisNav = new Glide( sliderNav[i], {
        type: 'carousel',
        animationDuration: 200,
        perTouch: 1,
        perView: 5,
        gap: 32,
        touchRatio: 1,
        focusAt: 'center',
        breakpoints: {
          1400: {
            perView:4
          }, 
          1160: {
            perView:3
          }, 
          930: {
            perView:2
          },
          700: {
            perView:1, 
            gap: 0
          }
        }
      } );
      //run this after mount so the clones are appended when you query select all the slider nav items
      thisNav.on('mount.after', function() {
        $( '.slider-nav-item' ).on( 'click', function( e ) {
          e.preventDefault();
          thisTrack.go( '='+(e.target.dataset.index ) ) 
        })
      });       
      thisNav.mount();
    
      $( '.glide__arrow' ).on(  'click', () => {
        thisTrack.go( '='+( thisNav.index ) )
      })
    
      thisTrack.on( 'swipe.end', () => {
        thisNav.go( '='+( thisTrack.index ) )
      })
      
      thisTrack.on( 'move.after', () => {
        thisNav.go( '='+( thisTrack.index ) )
      })
    }
  }
}