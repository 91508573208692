// this is for any GSAP related anims
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import LazyLoad from 'vanilla-lazyload';

let ScrollTriggerInit = gsap.registerPlugin( ScrollTrigger );
let svg = document.querySelectorAll( '.svg' );
let i;

if ( $( '.side-by-side-section' ).length ) {
  ScrollTriggerInit
}

if ( $( '.cta-parallax-block' ).length ) {
  ScrollTriggerInit
}

if( $( svg ).length ) {

  ScrollTriggerInit 
  svg.forEach( ( elem, i ) => {
    
    let svgTrigger = svg[i].closest('.svg-line-trigger');

    ScrollTrigger.create( {
      trigger: svgTrigger,
      start: 'top 80%',
      end: '50% 80%',
      scrub: 2,
      invalidateOnRefresh: true,
      onEnter: () => $( elem ).addClass( 'svg-active' ),
      onEnterBack: () => $( elem ).addClass( 'svg-active' ),
      onLeaveBack: () => $( elem ).removeClass( 'svg-active' )
    });
  });
}

var parallaxItem = document.querySelectorAll( '.parallax-item' );
var parallaxTrigger = document.querySelectorAll( '.parallax-trigger' );
// parallax images
if ( parallaxItem.length ) {
  parallaxItem.forEach( ( item, i ) => {
    gsap.fromTo( item, { y: 100 }, { 
      y: -100,
      scrollTrigger: {
        trigger: parallaxTrigger,
        start: '20% bottom',
        scrub: 2
      }
    });
  });

  if ( $( '.svg-parallax-item-bottom' ).length ) {
    gsap.fromTo( '.svg-parallax-item-bottom' , { y: 300 }, {
      y: -300, 
      scrollTrigger: {
        trigger: parallaxTrigger,
        start: '20% bottom',
        end: '80% top',
        scrub: 2
      }
    });
  }

  var svg_tl = gsap.timeline( { scrollTrigger: { trigger: parallaxTrigger, start:'20% bottom', end: '20% top', scrub:2 } } )
  
  svg_tl.fromTo( '.svg-mask', { y:150 },{ y:-150 }, 0 )
  svg_tl.fromTo( '.svg-container-top-mask svg', { y:-150 }, { y: 150 }, 0 );
}

// Video pause play functionality
var playPauseElems = document.querySelectorAll( 'video , lottie-player' );

$( window ).on( 'load', function() {
  if ( playPauseElems.length ){
    playPauseElems.forEach( ( video, i ) => {
  
      ScrollTrigger.create( {
        trigger: video,
        onToggle: self => {
  
          if ( self.isActive ) {
            video.play();
  
          } else {
            video.pause();
          }
        }
      });
    });
  }

});


// This fixes issue where lazy load slider images pushed scroll trigger markers to incorrect positions
if( $( '.slider-image' ).length ) {
  var callback_loaded = function (element) {
    setTimeout( () => {
      ScrollTrigger.refresh()
    }, 1500 ) 
  };
  
  var options = {
    callback_loaded : callback_loaded
  }
  
  let lazyImg = document.querySelectorAll('.slider-image')
  
  lazyImg.forEach( (elem, i) => {
    LazyLoad.load(elem, options)
  } )
}
