import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

let ScrollTriggerInit = gsap.registerPlugin( ScrollTrigger );

$( window ).on( 'load', () => {
  if( $( '.canvas' ).length ) {
    ScrollTriggerInit
  
    const fullWidthCanvas = document.querySelectorAll( '.fw-script-canvas' )
    const mainScript = document.querySelector( '.main-script' )
    const altScriptOne = document.querySelector( '.hero-alt-one' )
    const altScriptTwo = document.querySelector( '.hero-alt-two' )
  
    function canvasAnimation( elem ) {
      let txt = elem.querySelector( '.script-text' ).textContent // Grab script text
      let ctx = elem.getContext( '2d' )
      let dashLen = 30
      let dashOffset = dashLen
      let speed = 10; // Speed of writing animation
      let i = 0;
      let scale = window.devicePixelRatio 
      let size = 5000 // size of canvas in css
      let sizeY = size/50
      let x; // Where the text is being written in the X axis in pixels
      let y = ( sizeY / 2 ); // Where the text is being written in the Y axis in pixels

      // setting canvas size in css
      elem.style.width = size + 'px'
      elem.style.height = sizeY + 'px'
      
      // setting canvas size in html depending on window pixel ratio
      elem.width = Math.floor( size * scale )
      elem.height = Math.floor( sizeY * scale )

      // setting font size depending if its hero text or FW banner text
      let hasClass = $( elem ).hasClass( 'script-text-container canvas hero-script' )
      if( hasClass ) {
        ctx.font = '25px adobe-handwriting-ernie'
      } else {
        ctx.font = '60px adobe-handwriting-ernie'
      }
      
      // Script line Width
      ctx.lineWidth = 1/6;
  
      // get text width
      let textWidth = ctx.measureText( txt ).width

      // setting text alignment depending on placement
      let hasParent = $( elem ).parent( '.hero-script-alt-container' )

      if( hasParent[i] ) {
        x = 0
      } else {
        x = ( ( size/2 ) - ( ( textWidth )/2 ) )
      }
      
      // setting text alignment on Y axis
      // y = (size/100)
      
      // canvas styling and scaling
      ctx.scale( scale, scale )
      ctx.lineJoin = 'round'; 
      ctx.globalAlpha = 1;
      ctx.strokeStyle = ctx.fillStyle = '#FFF';
  
      // Function to draw in the script text
      function loop() {
        ctx.clearRect( x, 0, elem.width, 0 );
        ctx.setLineDash( [dashLen - dashOffset, dashOffset - speed] ); // create a long dash mask
        dashOffset -= speed; // reduce dash length

        if( txt[i] !== undefined ) {
          ctx.strokeText( txt[i], parseInt( x ), y ); // stroke letter
        } else {
          ctx.strokeText( ' ', parseInt( x ), y ) // If theres no letter make a space
        }

        if ( dashOffset > 0 ) {
          requestAnimationFrame( loop ); // animate
        } else {

          if( txt[i] !== undefined ) {
            ctx.fillText( txt[i], parseInt(x), y ); // stroke letter
          } else {
            ctx.fillText( ' ', parseInt(x), y ); // If theres no letter make a space
          }
          // fill final letter
          dashOffset = dashLen; // prep next char
          x += ctx.measureText( txt[i++] ).width + ctx.lineWidth;

          if ( i < txt.length ){ 
            requestAnimationFrame( loop ); 
          }
        }
      }

      loop() // Run the function
    }

    // Scroll trigger for Full Width Script Banner
    fullWidthCanvas.forEach( ( elem, index ) => {
      ScrollTrigger.create( { 
        trigger: $( '.fw-script-canvas' )[index],
        start: 'top bottom',
        onEnter: () => canvasAnimation( elem )
      } )
    } )

    // Calling hero script text
    canvasAnimation( mainScript )

    setTimeout( () => {
      canvasAnimation( altScriptOne )

      if( $( altScriptTwo ).length ) {
        setTimeout( () => {
          canvasAnimation( altScriptTwo )
        }, 500 )
      }

    }, 1500 )
  }
})

